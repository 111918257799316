import React from 'react';
import logo from './assets/svg/evilplan-logo.svg';
import map from './assets/svg/world-map.svg';
import moon from './assets/svg/moon-diagram.svg';
import team from './assets/images/thumbs-up.jpg';
import world from './assets/svg/world-domination.svg';
import './App.css';
// @ts-ignore
import Obfuscate from 'react-obfuscate';
import DiscordButton from "./DiscordButton";

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt=""/>
                <p>
                    <div className="App-link">
                        <Obfuscate email="conspire@evilplan.gg">Conspire</Obfuscate>
                    </div>
                </p>
            </header>
            <section className={"App-section"}>
                <img src={map} className="App-map" alt="logo"/>
                <h1 className={"App-text"} style={{
                    textAlign: "left",
                }}>
                    Plans are <span className={"pop"}>easy</span><br/>
                    World domination is <span className={"pop"}>hard</span>
                </h1>
            </section>
            <section className={"App-section"}>
                <img src={moon} className="App-moon" alt="moon"/>
                <h1 className={"App-text"} style={{
                    textAlign: "right",
                    marginTop: "calc(20px + -10vh)"
                }}>
                    We know to <span className={"pop"}>win</span><br/>
                    you must shoot the <span className={"pop"}>moon</span>
                </h1>
            </section>
            <section className={"App-section"}>
                <img src={world} className="App-world" alt="world"/>
                <h1 className={"App-text"} style={{
                    textAlign: "left",
                    marginTop: "calc(20px + -10vh)",
                }}>
                    at <span className={"pop"}>evilplan</span> we are <span className={"pop"}>laser-focused</span><br/>
                    on a core mission: <span className={"pop"}>global control</span>
                </h1>
            </section>
            <section className={"App-section"}>
                <div className={"App-team"}>
                    <img className={"App-team-image"} src={team}  alt="team"/>
                    <h1  className={"App-team-text"} style={{
                    }}>
                        <p>
                        our team is <span className={"pop-bright"}>ready</span><br/>
                        to welcome <span className={"pop-bright"}>you</span><br/>
                        </p>
                        <DiscordButton url={"https://discord.gg/MWyDFm93nX"}/>
                    </h1>
                </div>

            </section>
            <div style={{
                width: "100%",
                height: 22,
                fontSize: 10,
                fontWeight: 300,
                paddingTop: 4,
                paddingRight: 20,
                textAlign: "right",
                boxSizing: "border-box",
                color: "#b7b7b7"
            }}>
                © 2022 EvilPlan Inc.
            </div>
        </div>
    );
}

export default App;
