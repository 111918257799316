import React from "react";
import discord from './assets/svg/discord-logo.svg';

const DiscordButton = ({ isSSO, url }: {isSSO?: boolean, url: string}) => (
    <a
        href={url}
        style={{
            fontSize: 20,
            color: "white",
            fontWeight: "bold",
            borderRadius: 8,
            display: "inline-flex",
            alignItems: "center",
            padding: "10px 15px",
            backgroundColor: "#7289da",
            textDecoration: "none",
        }}
    >
        <img style={{
            width: 35,
            height: "auto",
            marginRight: 10,
            marginTop: 2,
            color: "white",
        }} src={discord} alt={"discord"}/>
        <span style={{
            textTransform: "uppercase"
        }}>{isSSO ? "Sign in with Discord" : "Join our Discord"}</span>
    </a>
);

export default DiscordButton;